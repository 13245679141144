import axios from "axios";

export interface BaseResponse {
  ok: boolean;
  error_msg: string;
}

export const client = axios.create({
  baseURL: 'https://e-kozuzem.hu',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  timeout: 3000
});
